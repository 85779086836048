import { endOfDay, startOfMonth } from 'date-fns';
import { isArray } from '../Utils/dataType.util';
import * as CONSTANTS from './type';
import {
  getUser, getAccountSettings, getAllUsers,
} from '../service/user';
import { cleanObject, generateName } from '../Utils/helper.util';
import { getActivities, getNotifications } from '../service/activity';
import { getCustomFields } from '../service/customFields';
import { getUpscaleSetting } from '../service/upscaleSetting';
import { getIntegrationSettings } from '../service/integrations';
import { getRecordings } from '../service/Twilio';
import { getAuthToken, getCountries } from '../service/country.state.city';
import { pluginConfig } from '../components/Plugins/commonConfigurations';
import { updateReduxStore } from '../Utils/creator.util';
import { UPDATE_PROSPECT_DETAIL } from './Prospect';
import { UPDATE_CLIENT_ACCOUNT_DETAIL } from './ClientAccount';
import { UPDATE_OPPORTUNITY_DETAIL } from './Opportunity';
import { setOutboxFailCount } from './Outbox/creator';
import { REMOVE_WORKFLOW, UPDATE_WORKFLOW_DETAIL } from './Workflow';
import { REMOVE_TEMPLATE, UPDATE_TEMPLATE_DETAIL } from './Template';

export function setUpdateInProgress(func, type) {
  return (dispatch) => {
    dispatch(
      updateReduxStore({ type }),
    );
    func && dispatch(func());
  };
}

function setLoaderCounter() {
  return {
    type: CONSTANTS.SPINNER_COUNTER,
  };
}

const dispatchLoaderCounterFinallyCallback = (dispatch, increaseCount) => {
  if (increaseCount) dispatch(setLoaderCounter());
};

function resetStore() {
  return {
    type: CONSTANTS.USER_LOGGED_OUT,
  };
}

// USER SETTING REDUCER HELPER ------------------------------
function setUserSettings() {
  return updateReduxStore({ type: CONSTANTS.USER_SETTINGS });
}

function setUserSettingsSuccess(payload) {
  return updateReduxStore({ type: CONSTANTS.USER_SETTINGS_SUCCESS, payload });
}

function setUserSettingsFailure(error) {
  return updateReduxStore({ type: CONSTANTS.USER_SETTINGS_FAILURE, payload: error });
}

function setUserSettingsThunk({ id }, increaseCount) {
  return (dispatch) => {
    dispatch(setUserSettings());
    getUser({ id })
      .then((userResp) => {
        dispatch(
          setUserSettingsSuccess({
            userSettings: userResp,
            user: {
              id,
              accountId: userResp.accountId,
              companyEmail: userResp.companyEmail,
              email: userResp.email,
              // organisationName: userResp.organisationName,
              // basicInfo: userResp.basicInfo,
            },
          }),
        );
      })
      .catch((error) => {
        dispatch(setUserSettingsFailure(error.message));
      })
      .finally(() => dispatchLoaderCounterFinallyCallback(dispatch, increaseCount));
  };
}

function setUpdateUserSettings(payload) {
  return updateReduxStore({ type: CONSTANTS.UPDATE_USER_SETTINGS, payload });
}

export function setUpdateUserSettingsSuccess(payload) {
  return updateReduxStore({ type: CONSTANTS.UPDATE_USER_SETTINGS_SUCCESS, payload });
}

export function setUpdateUserSettingsFailure(error) {
  return updateReduxStore({ type: CONSTANTS.UPDATE_USER_SETTINGS_FAILURE, payload: error });
}

export function setUpdateUserSettingsThunk({ accountId, id }) {
  return (dispatch) => {
    dispatch(setUpdateUserSettings({ accountId, id }));
    getUser({ accountId, id })
      .then((userResp) => {
        dispatch(
          setUpdateUserSettingsSuccess(userResp),
        );
      })
      .catch((error) => {
        dispatch(setUpdateUserSettingsFailure(error));
      });
  };
}

export function setUpdateUserSettingBasicSetting(payload) {
  return updateReduxStore({ type: CONSTANTS.UPDATE_USER_SETTING_BASIC_SETTING, payload });
}
// ------------------------------------------------------------

// ACCOUNT SETTING REDUCER HELPER --------------------
function setAccountSetting(accountId) {
  return updateReduxStore({ type: CONSTANTS.ACCOUNT_SETTINGS, payload: accountId });
}

export function setAccountSettingSuccess(payload) {
  return updateReduxStore({ type: CONSTANTS.ACCOUNT_SETTINGS_SUCCESS, payload });
}

function setAccountSettingFailure(error) {
  return updateReduxStore({ type: CONSTANTS.ACCOUNT_SETTINGS_FAILURE, payload: error });
}

function setAccountSettingThunk({ accountId, companyEmail }, increaseCount) {
  return (dispatch) => {
    dispatch(setAccountSetting(accountId));
    getAccountSettings({ accountId, companyEmail })
      .then((resp) => {
        dispatch(
          setAccountSettingSuccess({
            accountSettings: resp,
          }),
        );
      })
      .catch((error) => {
        dispatch(setAccountSettingFailure(error));
      })
      .finally(() => dispatchLoaderCounterFinallyCallback(dispatch, increaseCount));
  };
}

function updateAccountSettingThunk(payload) {
  return updateReduxStore({ type: CONSTANTS.UPDATE_ACCOUNT_SETTING, payload });
}

export function setNewAccountTags({ tags }) {
  return updateReduxStore({ type: CONSTANTS.UPDATE_ACCOUNT_TAGS, payload: { tags } });
}

export function setNewAccountExcludedDomains({ domainsExcludedFromReplyDetection }) {
  return updateReduxStore({ type: CONSTANTS.UPDATE_ACCOUNT_EXCLUDED_DOMAINS, payload: { domainsExcludedFromReplyDetection } });
}

export function setNewUpscaleCredits({ upscaleCredits, creditsLastUpdatedOn }) {
  return updateReduxStore({ type: CONSTANTS.UPDATE_ACCOUNT_CREDITS, payload: { upscaleCredits, creditsLastUpdatedOn } });
}
// -----------------------------------------------

// PROSPECT REDUCER HELPER -----------------------

export function removeProspects({ ids }) {
  return (dispatch) => {
    dispatch(updateReduxStore({ type: CONSTANTS.REMOVE_PROSPECT, payload: { ids } }));
  };
}

function setBulkFetchProspect({ ids }) {
  return (dispatch) => dispatch(removeProspects({ ids }));
}

function setSingleProspect(payload) {
  return (dispatch) => {
    // CHECK
    dispatch({ type: UPDATE_PROSPECT_DETAIL, payload });
    // dispatch({ type: UPDATE_PROSPECT_LIST_ON_SINGLE_UPDATE, payload });
  };
}

function setBulkUpdateProspect(payload) {
  return (dispatch) => {
    dispatch(updateReduxStore({ type: CONSTANTS.BULK_UPDATE_PROSPECT, payload }));
  };
}

function setBulkUpdateProspectCustomField(payload) {
  return (dispatch) => {
    dispatch(updateReduxStore({ type: CONSTANTS.BULK_UPDATE_PROSPECT_CUSTOM_FIELD, payload }));
  };
}

function setBulkUpdateProspectTags(payload) {
  return (dispatch) => {
    dispatch(updateReduxStore({ type: CONSTANTS.BULK_UPDATE_PROSPECT_TAGS, payload }));
  };
}

function setBulkUpdateProspectMultipleField(payload) {
  return (dispatch) => {
    dispatch(updateReduxStore({ type: CONSTANTS.BULK_UPDATE_PROSPECT_MULTIPLE_FIELD, payload }));
  };
}

function addProspectNote(payload) {
  return (dispatch) => {
    dispatch(updateReduxStore({ type: CONSTANTS.ADD_PROSPECT_NOTE, payload }));
  };
}
// -----------------------------------------------

// CLIENT ACCOUNT REDUCER HELPER ------------------------------

function setBulkFetchClientAccounts({ ids }) {
  return updateReduxStore({ type: CONSTANTS.REMOVE_CLIENT_ACCOUNT, payload: { ids } });
}

function removeClientAccount({ ids }) {
  return updateReduxStore({ type: CONSTANTS.REMOVE_CLIENT_ACCOUNT, payload: { ids } });
}

function setSingleClientAccount(payload) {
  // CHECK
  return updateReduxStore({ type: UPDATE_CLIENT_ACCOUNT_DETAIL, payload });
}

function setBulkUpdateClientAccount(payload) {
  return updateReduxStore({ type: CONSTANTS.BULK_UPDATE_CLIENT_ACCOUNT, payload });
}

function setBulkUpdateClientAccountCustomField(payload) {
  return updateReduxStore({ type: CONSTANTS.BULK_UPDATE_CLIENT_ACCOUNT_CUSTOM_FIELD, payload });
}

function setBulkUpdateClientAccountTags(payload) {
  return updateReduxStore({ type: CONSTANTS.BULK_UPDATE_CLIENT_ACCOUNT_TAGS, payload });
}

function setBulkUpdateClientAccountMultipleField(payload) {
  return updateReduxStore({ type: CONSTANTS.BULK_UPDATE_CLIENT_ACCOUNT_MULTIPLE_FIELD, payload });
}

function addClientAccountNote(payload) {
  return updateReduxStore({ type: CONSTANTS.ADD_CLIENT_ACCOUNT_NOTE, payload });
}
// -------------------------------------------------------------

// OPPORTUNITY REDUCER HELPER ----------------------------------

function removeOpportunity(payload) {
  return updateReduxStore({ type: CONSTANTS.REMOVE_OPPORTUNITY, payload });
}

function setSingleOpportunity(payload) {
  // CHECK
  return updateReduxStore({ type: UPDATE_OPPORTUNITY_DETAIL, payload });
}

function setBulkUpdateOpportunity(payload) {
  return updateReduxStore({ type: CONSTANTS.BULK_UPDATE_OPPORTUNITY, payload });
}

function setBulkUpdateOpportunityCustomField(payload) {
  return updateReduxStore({ type: CONSTANTS.BULK_UPDATE_OPPORTUNITY_CUSTOM_FIELD, payload });
}

function setBulkUpdateOpportunityMultipleField(payload) {
  return updateReduxStore({ type: CONSTANTS.BULK_UPDATE_OPPORTUNITY_MULTIPLE_FIELD, payload });
}

function setBulkUpdateOpportunityTags(payload) {
  return updateReduxStore({ type: CONSTANTS.BULK_UPDATE_OPPORTUNITY_TAGS, payload });
}

function addOpportunityNote(payload) {
  return updateReduxStore({ type: CONSTANTS.ADD_OPPORTUNITY_NOTE, payload });
}

// ---------------------------------------------------------------

// TEMPLATE REDUCER HELPER ---------------------------------------

function setBulkUpdateTemplate(payload) {
  return updateReduxStore({ type: CONSTANTS.BULK_UPDATE_TEMPLATE, payload });
}

function setSingleTemplate(payload) {
  return updateReduxStore({ type: UPDATE_TEMPLATE_DETAIL, payload });
}

export function removeTemplate(payload) {
  return updateReduxStore({ type: REMOVE_TEMPLATE, payload });
}
// --------------------------------------------------------------------

function setResetActivity() {
  return {
    type: CONSTANTS.RESET_ACTIVITY,
  };
}

function setActivity(payload) {
  return updateReduxStore({ type: CONSTANTS.FETCH_ACTIVITY, payload });
}

function setActivitySuccess(payload) {
  return updateReduxStore({ type: CONSTANTS.FETCH_ACTIVITY_SUCCESS, payload });
}

function setActivityFailure(error) {
  return updateReduxStore({ type: CONSTANTS.FETCH_ACTIVITY_FAILURE, payload: error });
}

function setRecursiveActivityThunk({
  userId, accountId, startTime, endTime, increaseCount,
}) {
  return (dispatch) => {
    dispatch(setActivity());
    getActivities({
      accountId, userId, startTime, endTime, sendEntityDetails: true,
    }, { operation: 'dashboardChunck' })
      .then((resp) => {
        const { resultArr, totalCount } = resp;
        dispatch(setActivitySuccess({
          activities: resultArr,
          startTime,
          endTime,
          totalCount,
        }));
      }).catch((error) => {
        dispatch(setActivityFailure(error));
      }).finally(() => {
        if (increaseCount) dispatch(setLoaderCounter());
      });
  };
}

function setNextActivityThunk({
  totalCount, activities,
}) {
  return (dispatch) => {
    dispatch({
      type: CONSTANTS.FETCH_NEXT_ACTIVITY, payload: { activities, totalCount },
    });
  };
}
function setActivityThunk({
  accountId, userId, startTime = startOfMonth(new Date()).getTime(), endTime = endOfDay(Date.now()).getTime(),
}, increaseCount) {
  return (dispatch) => {
    dispatch(setResetActivity());
    dispatch(setRecursiveActivityThunk({
      accountId, userId, startTime, endTime, increaseCount,
    }));
  };
}

export function setNotificationThunk({
  accountId,
}) {
  return (dispatch) => {
    dispatch({
      type: CONSTANTS.FETCH_NOTIFICATION, payload: { accountId },
    });
    getNotifications({ accountId, limit: 100 })
      .then((resp) => {
        const { resultArr, totalCount } = resp;
        dispatch({
          type: CONSTANTS.FETCH_NOTIFICATION_SUCCESS, payload: { items: resultArr, totalCount },
        });
      }).catch((error) => {
        dispatch({
          type: CONSTANTS.FETCH_NOTIFICATION_FAILURE, payload: error.message,
        });
      });
  };
}

function removeWorkflow(payload) {
  return updateReduxStore({ type: REMOVE_WORKFLOW, payload });
}

function setSingleWorflow(payload) {
  return updateReduxStore({ type: UPDATE_WORKFLOW_DETAIL, payload });
}

// ---------------------------------------------------------------------

// ALL USER REDUCER HELPER ---------------------------------------------

function setAllUsers(accountId) {
  return updateReduxStore({ type: CONSTANTS.ALL_USERS, payload: accountId });
}

function setAllUsersSuccess(payload) {
  return updateReduxStore({ type: CONSTANTS.ALL_USERS_SUCCESS, payload });
}

function setAllUsersFailure(error) {
  return updateReduxStore({ type: CONSTANTS.ALL_USERS_FAILURE, payload: error });
}

function setAllUsersThunk(accountId, increaseCount) {
  return (dispatch) => {
    dispatch(setAllUsers(accountId));
    getAllUsers(accountId)
      .then((Items) => {
        if (isArray(Items)) {
          // eslint-disable-next-line no-param-reassign
          Items = Items.map((d) => ({ ...d, ownerName: generateName(d.basicInfo) }));
        }
        dispatch(setAllUsersSuccess({
          allUsers: Items,
        }));
      }).catch((error) => {
        dispatch(setAllUsersFailure(error));
      })
      .finally(() => dispatchLoaderCounterFinallyCallback(dispatch, increaseCount));
  };
}

function setBulkFetchUsers(payload) {
  return updateReduxStore({ type: CONSTANTS.FETCH_BULK_USER, payload });
}

function removeUser(payload) {
  return updateReduxStore({ type: CONSTANTS.REMOVE_USER, payload });
}

function setSingleUser(payload) {
  return updateReduxStore({ type: CONSTANTS.UPDATE_USER, payload });
}

function addSingleUser(payload) {
  return updateReduxStore({ type: CONSTANTS.CREATE_USER, payload });
}

function setBulkUpdateUser(payload) {
  return updateReduxStore({ type: CONSTANTS.BULK_UPDATE_USER, payload });
}

function setBulkUpdateUserEmailThrottleMetaInfo(payload) {
  return updateReduxStore({ type: CONSTANTS.BULK_UPDATE_USER_EMAIL_THROTTLE_METAINFO, payload });
}
// --------------------------------------------------------------------

function setCustomFields(payload) {
  return updateReduxStore({ type: CONSTANTS.FETCH_CUSTOM_FIELDS, payload });
}

function setCustomFieldsSuccess(payload) {
  return updateReduxStore({ type: CONSTANTS.FETCH_CUSTOM_FIELDS_SUCCESS, payload });
}

function setCustomFieldsFailure(error) {
  return updateReduxStore({ type: CONSTANTS.FETCH_CUSTOM_FIELDS_FAILURE, payload: error });
}

function setCustomFieldsThunk({ accountId }, increaseCount) {
  return (dispatch) => {
    dispatch(setCustomFields({ accountId }));
    getCustomFields({ accountId, operation: 'getAll' })
      .then((resp) => {
        const { data: { result, statusCode, message } } = resp;
        if (statusCode === 200) {
          const {
            ruleset, callDisposition, callPurpose, prospectStage, accountCustomField, prospectCustomField,
            profile, twilioNumber = [], upscaleHubspotUserMapping, upscaleHubspotStageMapping, upscalePipedriveUserMapping,
            opportunityStage = [], opportunityCustomField = [], upscalePipedriveOpportunityStageMapping, trigger = [],
            upscaleSalesforceUserMapping, upscaleSalesforceStageMapping, schedule = [],
            upscaleSalesforceOpportunityStageMapping, mailbox = [], mailboxOptions = [],
            upscaleZohoStageMapping, upscaleZohoUserMapping, upscaleZohoOpportunityStageMapping,
            upscaleSlackUserMapping,
          } = result;
          dispatch(
            setCustomFieldsSuccess(cleanObject({
              ruleSets: ruleset,
              callDispositions: callDisposition,
              callPurposes: callPurpose,
              prospectStages: prospectStage,
              accountCustomFields: accountCustomField,
              prospectCustomFields: prospectCustomField,
              profiles: profile,
              twilioNumbers: twilioNumber,
              upscaleSlackUserMapping: upscaleSlackUserMapping?.[0] || {},
              upscaleHubspotUserMapping: upscaleHubspotUserMapping?.[0] || {},
              upscaleHubspotStageMapping: upscaleHubspotStageMapping?.[0] || {},
              upscaleZohoUserMapping: upscaleZohoUserMapping?.[0] || {},
              upscaleZohoStageMapping: upscaleZohoStageMapping?.[0] || {},
              upscalePipedriveUserMapping: upscalePipedriveUserMapping?.[0] || {},
              opportunityStages: opportunityStage,
              opportunityCustomFields: opportunityCustomField,
              upscalePipedriveOpportunityStageMapping: upscalePipedriveOpportunityStageMapping?.[0] || {},
              upscaleZohoOpportunityStageMapping: upscaleZohoOpportunityStageMapping?.[0] || {},
              triggers: trigger,
              upscaleSalesforceUserMapping: upscaleSalesforceUserMapping?.[0] || {},
              upscaleSalesforceStageMapping: upscaleSalesforceStageMapping?.[0] || {},
              upscaleSalesforceOpportunityStageMapping: upscaleSalesforceOpportunityStageMapping?.[0] || {},
              schedules: schedule,
              mailboxes: mailbox,
              mailboxOptions,
            })),
          );
        } else {
          throw new Error(message);
        }
      })
      .catch((error) => {
        dispatch(setCustomFieldsFailure(error));
      })
      .finally(() => dispatchLoaderCounterFinallyCallback(dispatch, increaseCount));
  };
}

function setUpscaleSetting(payload) {
  return updateReduxStore({ type: CONSTANTS.FETCH_UPSCALE_SETTINGS, payload });
}

function setUpscaleSettingSuccess(payload) {
  return updateReduxStore({ type: CONSTANTS.FETCH_UPSCALE_SETTINGS_SUCCESS, payload });
}

function setUpscaleSettingFailure(error) {
  return updateReduxStore({ type: CONSTANTS.FETCH_UPSCALE_SETTINGS_FAILURE, payload: error });
}

function setUpscaleSettingThunk({ plan }, increaseCount) {
  return (dispatch) => {
    dispatch(setUpscaleSetting({ plan }));
    getUpscaleSetting(plan)
      .then((resp) => {
        dispatch(
          setUpscaleSettingSuccess({
            upscaleSetting: resp,
          }),
        );
      })
      .catch((error) => {
        dispatch(setUpscaleSettingFailure(error));
      })
      .finally(() => dispatchLoaderCounterFinallyCallback(dispatch, increaseCount));
  };
}

function setIntegrationSetting(payload) {
  return updateReduxStore({ type: CONSTANTS.FETCH_INTEGRATION_SETTINGS, payload });
}

function setIntegrationSettingSuccess(payload) {
  return updateReduxStore({ type: CONSTANTS.FETCH_INTEGRATION_SETTINGS_SUCCESS, payload });
}

function setIntegrationSettingFailure(error) {
  return updateReduxStore({ type: CONSTANTS.FETCH_INTEGRATION_SETTINGS_FAILURE, payload: error });
}

function setTwilioNumbersFailure(payload) {
  return updateReduxStore({ type: CONSTANTS.FETCH_OWNED_NUMBERS_SETTINGS_FAILURE, payload });
}

function setIntegrationSettingThunk({ accountId }) {
  return (dispatch) => {
    dispatch(setIntegrationSetting({ accountId }));
    getIntegrationSettings({ accountId })
      .then((resp) => {
        const integrationMap = {};
        const { Items = [], apiKeys } = resp;
        if (isArray(Items)) {
          Items.forEach((d) => {
            const { name } = d;
            integrationMap[name] = d;
          });
        }
        dispatch(
          setIntegrationSettingSuccess({
            apiKeys,
            integrationMap,
            integrations: Items,
            syncedCRM: Object.keys(pluginConfig).find((d) => integrationMap[d]),
          }),
        );
      })
      .catch((error) => dispatch(setIntegrationSettingFailure(error)));
  };
}

function setTwilioRecordingsSuccess(payload) {
  return updateReduxStore({ type: CONSTANTS.FETCH_TWILIO_RECORDINGS_SUCCESS, payload });
}

//
function setTwilioRecordings({ accountId }) {
  return (dispatch) => {
    getRecordings({ accountId })
      .then((resp) => {
        if (resp && isArray(resp?.resultArr)) {
          const { resultArr } = resp;
          const mappedData = {};
          resultArr.forEach((d) => {
            const { id } = d;
            mappedData[id] = d;
          });
          dispatch(
            setTwilioRecordingsSuccess({
              recordings: mappedData,
            }),
          );
        } else if (resp?.error) {
          throw new Error(resp?.error);
        } else throw new Error('Something went wrong');
      })
      .catch((error) => dispatch(setTwilioNumbersFailure(error.message)));
  };
}
//

function setCountryDataFailure(payload) {
  return updateReduxStore({ type: CONSTANTS.FETCH_COUNTRY_DATA_FAILURE, payload });
}

function setCountryDataSuccess(payload) {
  return updateReduxStore({ type: CONSTANTS.FETCH_COUNTRY_DATA_SUCCESS, payload });
}

function setCountryData() {
  return async (dispatch) => {
    try {
      const tokenResp = await getAuthToken();
      const resp = await getCountries({ token: tokenResp.auth_token });
      if (isArray(resp)) {
        dispatch(
          setCountryDataSuccess({
            countryList: resp,
            auth_token: tokenResp.auth_token,
          }),
        );
      }
    } catch (error) {
      dispatch(setCountryDataFailure(error));
    }
  };
}

function setRequiredDataThunk({
  accountId, companyEmail, id, isSuperAdminLogin = false, restrictAccessToData = false,
}) {
  if (isSuperAdminLogin) {
    return (dispatch) => {
      dispatch(setCountryData());
    };
  }
  if (restrictAccessToData) {
    return (dispatch) => {
      dispatch(setUpscaleSettingThunk({ plan: 'basic' }, true));
      dispatch(setUpdateUserSettingsThunk({ accountId, id }));
      dispatch(setAccountSettingThunk({ accountId, companyEmail }, true));
      dispatch(setAllUsersThunk(accountId), true);
    };
  }
  return (dispatch) => {
    dispatch(setOutboxFailCount(accountId));
    dispatch(setUpscaleSettingThunk({ plan: 'basic' }, true)); // 1
    dispatch(setUpdateUserSettingsThunk({ accountId, id }));
    dispatch(setAccountSettingThunk({ accountId, companyEmail }, true)); // 1
    dispatch(setAllUsersThunk(accountId), true);
    dispatch(setActivityThunk({ accountId, userId: id }, false));
    dispatch(setCustomFieldsThunk({ accountId }, true)); // 1
    dispatch(setTwilioRecordings({ accountId }));
    dispatch(setCountryData());
    dispatch(setIntegrationSettingThunk({ accountId }));
    dispatch(setNotificationThunk({ accountId }));
  };
}

export function setUpdateUserProfileThunk({ accountId, id, companyEmail }) {
  return (dispatch) => {
    dispatch(setRequiredDataThunk({ accountId, id, companyEmail }));
  };
}

function hideDemo() {
  return (dispatch) => {
    dispatch({
      type: CONSTANTS.HIDE_DEMO,
    });
  };
}

function setSocketEvent(event) {
  return updateReduxStore({ type: CONSTANTS.SOCKET_EVENT, payload: event });
}

function setOffline(payload) {
  return updateReduxStore({ type: CONSTANTS.SET_OFFLINE, payload });
}

function setActivitiesById(payload) {
  return updateReduxStore({ type: CONSTANTS.FETCH_ACTIVITIES_BY_ID, payload });
}

function removeActivity(payload) {
  return updateReduxStore({ type: CONSTANTS.REMOVE_ACTIVITY, payload });
}

export function setAddNotification(payload) {
  return updateReduxStore({ type: CONSTANTS.ADD_NEW_NOTIFICATION, payload });
}

export function setUpdateNotification(payload) {
  return updateReduxStore({ type: CONSTANTS.UPDATE_NOTIFICATION, payload });
}

export function setBulkUpdateNotification(payload) {
  return updateReduxStore({ type: CONSTANTS.BULK_UPDATE_NOTIFICATION, payload });
}

export function setFetchNextNotifications(payload) {
  return updateReduxStore({ type: CONSTANTS.FETCH_NEXT_NOTIFICATION, payload });
}

export {
  resetStore, setUserSettingsThunk,
  setAccountSettingThunk, setRequiredDataThunk,
  setAllUsersThunk,
  hideDemo, setActivityThunk,
  setCustomFieldsThunk, setIntegrationSettingThunk,
  setOffline, setSocketEvent, removeWorkflow,
  setBulkFetchUsers, removeUser, setBulkFetchClientAccounts, removeClientAccount,
  setActivitiesById, removeActivity, setTwilioRecordings,
  removeOpportunity,
  setSingleProspect, setBulkUpdateProspect, setBulkUpdateProspectCustomField, setBulkUpdateProspectTags, addProspectNote, setSingleClientAccount,
  setBulkUpdateClientAccountTags, setBulkUpdateProspectMultipleField, setBulkUpdateClientAccountMultipleField, addClientAccountNote,
  setBulkUpdateClientAccount, setBulkUpdateClientAccountCustomField, setSingleOpportunity, setBulkUpdateOpportunity, addOpportunityNote,
  setBulkUpdateOpportunityCustomField, setBulkUpdateOpportunityMultipleField,
  setBulkUpdateTemplate, setSingleTemplate, setBulkUpdateOpportunityTags, updateAccountSettingThunk,
  setSingleWorflow, setSingleUser, setBulkUpdateUser, setBulkUpdateUserEmailThrottleMetaInfo,
  addSingleUser, setNextActivityThunk, setBulkFetchProspect,
};
