import { Auth, Amplify } from 'aws-amplify';
import { getUser, setCognitoId } from './user';
import { logEvent, MIXPANEL_EVENTS, onlyIdentifyUser } from '../libs/mixpanel';
import { AlertError, AlertSuccess } from '../components/Common/Alerts';
import { Logger } from '../libs/logger';
import { isFunction } from '../Utils/dataType.util';
import { fireAppEvent } from '../Utils/emitter.util';
import { voidFunction } from '../Utils/helper2.util';
import { API_NAME, BILLING_ROUTE, MAILBOX_ROUTE, REGISTERATION_PASSWORD } from '../constants';
import { onError } from '../libs/errorLib';
import { isSuperAdmin, cognitoUserNeedsToUpgrade, isSelfSignedCognitoUserOnFreeTrial, removeAllExistingPoups } from '../libs/authentication.lib';

const forgotPassword = async (email) => { await Auth.forgotPassword(email); };

const forgotPasswordSubmit = async ({
  email, authCode, password, history,
}) => {
  await Auth.forgotPasswordSubmit(email, authCode, password)
    .then(async () => {
      history.push('/login');
    });
};

// Signup with Email to send the verification link
const signUp = async ({
  username, password, enableAutoSignIn = false, attributes = {},
}) => Auth.signUp({
  username,
  password,
  attributes,
  autoSignIn: { // optional - enables auto sign in after user is confirmed
    enabled: enableAutoSignIn,
  },
});

const getCurrentCreds = () => Auth.currentUserInfo();

// TODO: uncomment to send an adhoc invitation email
// await signUp(email, REGISTERATION_PASSWORD);
const handleLoginAndRedirect = async (email, password, firstTimeSignInCallback) => Auth.signIn(email, password)
  .then(async (user) => {
    const { attributes } = user;
    const currentCreds = await getCurrentCreds();
    const { id, username } = currentCreds;
    if (attributes['custom:onboardedBy'] === 'self' && !attributes['custom:onboardedBy']?.includes('selfSignupComplete:') && id) {
      const name = attributes['custom:name'];
      const lName = name.split('-')[1] || '';
      await firstTimeSignInCallback({
        fName: name.split('-')[0],
        lName,
        phoneNumber: attributes['custom:phoneNumber'],
        cName: attributes['custom:companyName'],
        confirmationEmail: email,
        identityId: id,
        userName: username,
      });
      return { identityId: id, routeToLand: MAILBOX_ROUTE };
    }
    // TODO: Change this login to impose this restriction on expiry of plan instead of type of plan
    // when we expiry time for non free trial customers too
    // if(cognitoUserNeedsToUpgrade(attributes))
    if (isSelfSignedCognitoUserOnFreeTrial(attributes) && cognitoUserNeedsToUpgrade(attributes)) {
      return {
        identityId: id, routeToLand: BILLING_ROUTE,
      };
    }
    onlyIdentifyUser(id);
    logEvent(MIXPANEL_EVENTS['Sign In'], {}, { property: '#totalSignedIn', value: 1 });
    return {
      identityId: id, routeToLand: isSuperAdmin(email) ? '/admin' : '/',
    };
  });

// First time sign in for invited user
const firstTimeSignIn = async (email, newPassword) => Auth.signIn(email, REGISTERATION_PASSWORD)
  .then(async (user) => {
    await Auth.changePassword(user, REGISTERATION_PASSWORD, newPassword);
    return user;
  }).then(async () => {
    await setCognitoId({ email });
    const { id } = await getCurrentCreds();
    onlyIdentifyUser(id);
    logEvent(MIXPANEL_EVENTS['User Verified'], { email, id });
    logEvent(MIXPANEL_EVENTS['Sign Up'], { email, id });
    return id;
  }).catch((error) => {
    logEvent(MIXPANEL_EVENTS['Sign Up Failed'], { email, time: Date.now(), error: error.message });
    onError(new Error(`${email} failed to sign up due to ${error.message}`));
    AlertError({ head: 'Sign up failed, please contact upscale admin' });
    throw new Error(error.message);
  });

const confirmSignUp = async (email, authCode) => {
  await Auth.confirmSignUp(email, authCode).then(() => {
    if (typeof window.gr === 'function') {
      // 👇️ this runs
      Logger.log('✅ gr function is defined');

      window.gr('track', 'conversion', { email });
    } else {
      Logger.log('⛔️ gr function is NOT defined'); // Normally this means the gr function wasn't declared here, try and look why the Tracking Script isn't running on this page
    }
  });
};

const changePassword = async (oldPass, newPass) => {
  await Auth.currentAuthenticatedUser()
    .then((user) => Auth.changePassword(user, oldPass, newPass));
};

// Sign out user from application
const signOut = async (global = false, callback = () => { /* do something */ }) => Auth.signOut({ global })
  .catch((error) => {
    Logger.error('ERROR ON SIGNOUT - ', error);
  })
  .finally(() => {
    removeAllExistingPoups();
    callback && callback();
  });

// Getting fresh session at the start of application
const hasActiveSession = async (onSuccessCallback, onFailureCallback) => Auth.currentSession().then(() => {
  isFunction(onSuccessCallback) && onSuccessCallback();
}).catch((e) => signOut(true, () => isFunction(onFailureCallback) && onFailureCallback(e.message)));

// Checking and then getting fresh session once inside the  application
const getSessionWithinApp = async (onSuccessCallback = voidFunction) => Auth.currentSession().then(() => {
  onSuccessCallback();
}).catch(async () => {
  signOut(true, () => fireAppEvent('resetStore'));
});

// Send the confirmation link or code for signup
const resendConfirmationCode = async (email, clientMetadata) => {
  try {
    await Auth.resendSignUp(email, clientMetadata);
    AlertSuccess({
      head: 'Verification Link Sent',
    });
  } catch (err) {
    AlertError({
      head: 'Something went wrong',
    });
  }
};

const configureAWSAmplify = () => {
  Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      signUpVerificationMethod: 'code',
      region: process.env.REACT_APP_REGION,
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    },
    Storage: {
      bucket: process.env.REACT_APP_S3_ATTACHMENT_BUCKET_NAME,
      region: process.env.REACT_APP_REGION,
      // identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    },
    API: {
      endpoints: [
        {
          name: API_NAME,
          endpoint: process.env.REACT_APP_API_BASE_URL,
          region: process.env.REACT_APP_REGION,
        },
      ],
    },
  });
};

export const getUserDetails = async (id) => getUser({ id })
  .then((userResp) => ({
    user: {
      id,
      accountId: userResp.accountId,
      companyEmail: userResp.companyEmail,
      email: userResp.email,
      ...userResp,
    },
  }))
  .catch((error) => ({ error: error.message }));

export {
  forgotPassword, handleLoginAndRedirect, confirmSignUp, signUp, resendConfirmationCode,
  forgotPasswordSubmit, hasActiveSession, configureAWSAmplify, changePassword,
  signOut, getCurrentCreds, getSessionWithinApp, firstTimeSignIn,
};
