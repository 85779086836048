import {
  FETCH_CLIENT_ACCOUNT_PROFILE_ACTIVITY, FETCH_CLIENT_ACCOUNT_PROFILE_ACTIVITY_BY_ID, FETCH_CLIENT_ACCOUNT_PROFILE_ACTIVITY_FAILURE, FETCH_CLIENT_ACCOUNT_PROFILE_ACTIVITY_SUCCESS,
  FETCH_NEXT_CLIENT_ACCOUNT_PROFILE_ACTIVITY,
  RESET_CLIENT_ACCOUNT_PROFILE_ACTIVITY,
} from '../../action/type';
import { updateActivitiesInRedux } from '../../Utils/reducer.util';

const initialState = {
  isLoading: '',
  totalCount: 0,
  entityId: '',
  activities: [],
  prospectEmails: [],
};

export const clientAccountProfileActivityReducer = (curState = initialState, action = { payload: '', action: '' }) => {
  let newState;
  const { type, payload } = action;
  switch (type) {
    case RESET_CLIENT_ACCOUNT_PROFILE_ACTIVITY:
      newState = { ...initialState };
      break;
    case FETCH_CLIENT_ACCOUNT_PROFILE_ACTIVITY:
      newState = {
        ...curState,
        isLoading: FETCH_CLIENT_ACCOUNT_PROFILE_ACTIVITY,
        ...payload,
      };
      break;
    case FETCH_CLIENT_ACCOUNT_PROFILE_ACTIVITY_SUCCESS: {
      const {
        activities, totalCount, entityId, prospectEmails,
      } = payload;
      if (entityId === curState.entityId) {
        newState = {
          ...curState,
          totalCount,
          entityId,
          isLoading: FETCH_CLIENT_ACCOUNT_PROFILE_ACTIVITY_SUCCESS,
          activities: activities.sort((a, b) => b.createdAt - a.createdAt),
          prospectEmails,
        };
      } else {
        newState = curState;
      }
      break;
    }
    case FETCH_CLIENT_ACCOUNT_PROFILE_ACTIVITY_FAILURE:
      newState = {
        ...curState,
        error: payload.error,
        isLoading: FETCH_CLIENT_ACCOUNT_PROFILE_ACTIVITY_FAILURE,
      };
      break;
    case FETCH_CLIENT_ACCOUNT_PROFILE_ACTIVITY_BY_ID: {
      newState = updateActivitiesInRedux({ payload, curState });
      break;
    }
    case FETCH_NEXT_CLIENT_ACCOUNT_PROFILE_ACTIVITY: {
      const {
        activities, totalCount, entityId,
      } = payload;
      const { activities: curActivities = [] } = curState;
      if (entityId === curState.entityId) {
        newState = {
          ...curState,
          totalCount,
          isLoading: FETCH_NEXT_CLIENT_ACCOUNT_PROFILE_ACTIVITY,
          activities: curActivities.concat(activities).sort((a, b) => b.createdAt - a.createdAt),
        };
      } else {
        newState = curState;
      }
      break;
    }
    default:
      newState = curState;
  }

  return newState;
};
