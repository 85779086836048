import API from '../libs/amplify.lib';
import { API_NAME } from '../constants';
import { generateQueryStringFromObject } from '../Utils/query.v2.util';
import { parseResponse, responseCallback } from '../libs/response.lib';

export const getActivities = async (payload, query = {}) => API.post(API_NAME, `/activity?${generateQueryStringFromObject(query)}`, { body: { ...payload, limit: 5 } })
  .then(parseResponse);

export const getTriggerActivityViaFilter = async ({
  accountId, LastEvaluatedKey = {}, triggerId,
}) => API.post(API_NAME, '/activity?operation=triggerChunck', {
  body: {
    accountId, LastEvaluatedKey, triggerId, sendEntityDetails: true,
  },
});

export const batchGetActivities = async (payload, query = { operation: 'batchGet' }) => API.post(API_NAME,
  `/activity?${generateQueryStringFromObject(query)}`, { body: { ...payload, sendEntityDetails: true } })
  .then(responseCallback);

export const getEmailMetrics = async (payload, query = { operation: 'emailMetrics' }) => API.post(API_NAME, `/activity?${generateQueryStringFromObject(query)}`, { body: payload })
  .then(responseCallback);

export const getNotifications = async (payload) => API.post(API_NAME, '/activity?operation=notificationChunck', { body: payload })
  .then(parseResponse);

export const updateSingleNotification = async ({ accountId, id }) => API.put(API_NAME, '/activity?operation=markAsRead', { body: { accountId, id } })
  .then(responseCallback);

export const updateBulkNotification = async ({ accountId, ids }) => API.put(API_NAME, '/activity?operation=markAllAsRead', { body: { accountId, ids } })
  .then(responseCallback);
